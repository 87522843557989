class SimpleEventBus {
    constructor() {
        this._listenersMap = {};
        window.simpleEventBus = this;
    }

    subscribe(eventName, callback) {
        if (!this._listenersMap[eventName]) {
            this._listenersMap[eventName] = [];
        }
        this._listenersMap[eventName].push(callback);
    }

    unsubscribe(eventName, callback) {
        if (!this._listenersMap[eventName]) {
            return;
        }
        this._listenersMap[eventName] = this._listenersMap[eventName].filter(cb => cb !== callback);
    }

    emit(eventName, data) {
        if (!this._listenersMap[eventName]) {
            return;
        }
        this._listenersMap[eventName].forEach(cb => cb(data));
    }
}

export const simpleEventBus = new SimpleEventBus();
