import $ from 'jquery';
import {simpleEventBus} from './simpleEventBus';

class Application {
    constructor() {
        this.timeout = null;
    }

    go() {
        this.$sectors = $('.application__sector');
        this.$videoWrapper = $(document.getElementById('application__video-wrapper'));

        this.setListeners();
    }

    setListeners() {
        this.$sectors.hover((e) => {
            if (this.timeout) {
                return;
            }
            this.$sectors.removeClass('application__sector--active');
            $(e.target).addClass('application__sector--active');
        });

        this.$sectors.mouseleave((e) => {
            if (this.timeout) {
                return;
            }
            $(e.target).removeClass('application__sector--active');
        });

        this.$sectors.click(e => {
            if (this.timeout) {
                return;
            }
            const {target} = e;
            const videoName = target.getAttribute('data-video');

            this.timeout = 'animastart';
            this.animationStart(videoName);

            const t = setTimeout(() => {
                this.play(videoName);
                clearTimeout(t);
            }, 1800);
        });

        this.$videoWrapper.mousemove(() => {
            this.showStuff();

            if (this.timeout !== null) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }

            this.timeout = setTimeout(() => {
                this.hideStuff();
            }, 1000);
        });

        simpleEventBus.subscribe('keydown.escape', () => this.end());
        simpleEventBus.subscribe('menu.item.click', () => this.end());
    }

    animationStart(videoName) {
        const rest = this.$sectors.filter(`:not([data-video="${videoName}"])`);
        const self = this.$sectors.filter(`[data-video="${videoName}"]`);
        const vlines = $('.application__vlines');

        rest.addClass('sector-fade-out');
        self.addClass('sector-expand');
        vlines.addClass('vlines-fade-out');


        const t = setTimeout(() => {
            rest.removeClass('sector-fade-out');
            self.removeClass('sector-expand');
            vlines.removeClass('vlines-fade-out');
        }, 3750);
    }

    play(videoName) {
        this.$videoWrapper.addClass('play');

        let fileName = '';

        if ($('body').hasClass('lang-en')) {
            fileName = videoName.replace('.mp4', '.en.mp4');
        } else {
            fileName = videoName;
        }

        const pt = setTimeout(() => {
            this.$videoWrapper.html(`
                <video autoplay muted id="application__video">
                    <source src="./videos/${fileName}" type="video/mp4">
                </video>
                <div class="video__close"></div>
                <div class="sound"></div>
            `);

            const videoEl = document.getElementById('application__video');
            const $sound = $('.sound');
            videoEl.onended = () => this.end();
            this.$videoWrapper.focus();

            this.hideStuff();

            simpleEventBus.emit('video.start');

            setTimeout(() => {
                $('.video__close').click((e) => {
                    this.showStuff();
                    this.end();
                });
                $sound.click(() => {
                    if (videoEl.muted) {
                        videoEl.muted = false;
                        $sound.addClass('sound--on');
                    } else {
                        videoEl.muted = true;
                        $sound.removeClass('sound--on');
                    }
                });
            }, 100);
            clearTimeout(pt);
        }, 700);
    }

    end() {
        this.$videoWrapper.removeClass('play');
        this.$videoWrapper.html('');
        clearTimeout(this.timeout);
        this.timeout = null;

        simpleEventBus.emit('video.end');

        const t = setTimeout(() => {
            this.showStuff();
            clearTimeout(t);
        }, 0)
    }

    showStuff() {
        window.showContacts();
        window.showMenu();
        $('.sound').removeClass('sound--fullscreen');
        $('.video__close').removeClass('video__close--fullscreen');
    }

    hideStuff() {
        window.hideContacts();
        window.hideMenu();
        $('.sound').addClass('sound--fullscreen');
        $('.video__close').addClass('video__close--fullscreen');
    }
}

export function application() {
    const a = new Application();
    a.go();
}
