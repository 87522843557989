import $ from 'jquery';

export function exploitation() {
    setActive("1");
    $('.exploitation__icon').click((e) => {
        const {currentTarget} = e;
        const number = currentTarget.getAttribute('data-section-number');
        setActive(number)
    });
}

function setActive(sectionNumber) {
    $('.exploitation__section').removeClass('active');
    $('.exploitation__icon').removeClass('active');
    $('.exploitation__video').removeClass('active');

    $(`.exploitation__section--${sectionNumber}`).addClass('active');
    $(`.exploitation__icon--${sectionNumber}`).addClass('active');
    $(`.exploitation__video--${sectionNumber}`).addClass('active');
}
