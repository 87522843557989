import $ from 'jquery';
import { clamp, noop } from "./utils";

export class Loader {

    constructor() {
        this._resources = {};
        this._onFinish = noop;
    }

    go() {
        this.$preloader = $('#preloader');
        this.$loadingScreen = $('#loadingScreen');
        this.$loaderValue = $('#loaderValue');
        this.$loaderBar = $('#loaderBar');

        this.$loadingScreen.show();

        this._loadAll();
    }

    _setTotal(src, value) {
        this._resources[src].total = value;
        window._kkk = this._resources;
    }

    _setProgress(src, value) {
        this._resources[src].progress = value;
        this._onUpdate();
    }

    _loadAll() {
        const urls = $('.imgForPreload, .videoForPreload').map((i, el) => el.getAttribute('data-src'));
        console.log(urls);

        urls.each((i, url) => {
            this._resources[url] = {total: 0, progress: 0};
            this._load(url);
        });
    }

    _onUpdate() {
        const total = Object
        // .values(this._resources)
            .keys(this._resources).map(x => this._resources[x])
            .map(x => x.total)
            .reduce((a, v) => a += v);
        const progress = Object
        // .values(this._resources)
            .keys(this._resources).map(x => this._resources[x])
            .map(x => x.progress)
            .reduce((a, v) => a += v);
        const value = 100 * progress / total;

        this._render(value);
        if (value === 100) {
            this.finish();
        }
    }

    _load(url) {
        const thisImg = this;
        const xmlHTTP = new XMLHttpRequest();
        xmlHTTP.open('GET', url, true);
        xmlHTTP.responseType = 'arraybuffer';

        xmlHTTP.onload = (e) => {
            const blob = new Blob([this.response]);
            thisImg.src = window.URL.createObjectURL(blob);
        };
        xmlHTTP.onprogress = (e) => {
            this._setTotal(url, e.total);
            this._setProgress(url, e.loaded);
        };
        xmlHTTP.send();
    }

    _render(value) {
        this.$loaderValue.html(value.toFixed(0));
        this.$loaderBar.attr('style', `width: ${clamp(value, 100, 0)}%`);
    }

    onFinish(callback) {
        this._onFinish = callback;
    }

    finish() {
        setTimeout(() => this.$loadingScreen.hide(), 1200);
        this._onFinish();
    }
}
