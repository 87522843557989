import $ from 'jquery';

export const home = () => {
    $('.home__description__icon').click((e) => {
        const index = (e.target).getAttribute('index');
        $('.home__description__icon').removeClass('home__description__icon-active');
        $(e.target).addClass('home__description__icon-active');

        const homeInfoActiveClass = 'home__description__info-active';
        $('.home__description__info').removeClass(homeInfoActiveClass);
        const selector = `.home__description__info[index=${index}]`;
        $(selector).addClass(homeInfoActiveClass);
    });
};

export default home;
