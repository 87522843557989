import $ from 'jquery';

export function connection() {
    const section = $('.section.connection')

    $('.connectionInteractive, .connection__pulse').on('click mouseover touch', (e) => {
        const t = setTimeout(() => {
            const mode = $(e.target).attr('data-mode');
            const frame = $('.connection__lens-frame');

            section.removeClass('active--node active--edge active--loop');
            section.addClass(`active--${mode}`);

            frame.removeClass('frame--node frame--edge frame--loop');
            frame.addClass(`frame--${mode}`);
            clearTimeout(t);
        }, 24)
    });

    $('.connectionInteractive, .connection__pulse').one('click mouseover touch', (e) => {
        const mode = $(e.target).attr('data-mode');
        const frame = $('.connection__lens-frame');
        const lens = $('.connection__pipe_img-lens');

        frame.addClass(`frame-inited--${mode}`);
        lens.addClass(`lens-inited--${mode}`);
    })
}
