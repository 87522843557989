import $ from 'jquery';
import {clamp, coroutine, noop} from "./utils";
import {simpleEventBus} from "./simpleEventBus";
import {debounce} from "./debounce";

export class Longread {
    /**
     *
     * @param flow - if false, then scrolling will call auto scroll to next/prev section
     */
    constructor({flow = true, noDrag = false}) {
        this.flow = flow;
        this.noDrag = noDrag;
        this._initValues();
        this._lastScroll = 0;
    }

    go() {
        this._init();
        this._setListeners();

        window.gotosection = (sectionNumber) => {
            this._scrollToGoal(-(parseInt(sectionNumber) - 1) * this._sectionHeight);
        };

        window.longread = this;

        window.onresize = debounce(() => {
            this.reset();
            this._magnet();
        }, 25);

        window.currentScroll = () => this._curentScroll();
    }

    reset() {
        this._init();
    }

    _initValues() {
        this.$wrapper = null;
        this._currentScroll = 0;
        this._sectionsCount = 0;
        this._sectionHeight = 0;
        this._maxAbsScroll = 0;
        this._isBusy = false;
        this._dragTolerance = 18;
    }

    _init() {
        this.$wrapper = $('#longread-sections-wrapper');
        this._sectionsCount = this.$wrapper.find('.section').length;
        this._sectionHeight = this.$wrapper[0].getBoundingClientRect().height;
        this._maxAbsScroll = this._sectionHeight * (this._sectionsCount - 1);

        this.$wrapper.focus();
    }


    _setListeners() {
        const _realScroll = (e) => {
            this._lastScroll = Date.now();
            const delta = e.originalEvent ? e.originalEvent.deltaY : 100;
            const count = 20;

            if (this.flow) {
                coroutine(() => this._flow(delta / count), count, 100);
            } else {
                this._goto(delta);
            }
        };

        let _wheelScroll = noop;

        const _setWheel = () => {
            _wheelScroll = debounce((e) => {
                _realScroll(e);
            }, 300, true);
        };

        _setWheel();

        // mouse drag
        this.$wrapper.mousedown(e => {
            if (this.noDrag || !this._canScroll) {
                return;
            }

            const {screenY} = e;
            let lastY = screenY;

            this.$wrapper.on('mousemove', e => {
                if (!this._canScroll()) {
                    this.$wrapper.off('mousemove');
                    this.$wrapper.off('mouseup');
                    return;
                }

                this._flow(lastY - e.screenY);

                if (!this.flow && Math.abs(screenY - e.screenY) > this._dragTolerance) {
                    this._goto(screenY - e.screenY);
                }

                lastY = e.screenY;
            });

            this.$wrapper.on('mouseup mouseleave', e => {
                if (!this.flow && this._canScroll()) {
                    this._magnet();
                }
                this.$wrapper.off('mousemove');
                this.$wrapper.off('mouseup');
            });
        });

        // mouse wheel
        this.$wrapper.on('wheel', e => {
            if (this._lastScroll !== 0 && Date.now() - this._lastScroll >= 500) {
                _setWheel();
            }
            _wheelScroll(e);
        });

        // keyboard arrows
        simpleEventBus.subscribe('keydown.arrowUp', () => {
            if (!this.flow && !this._canScroll()) {
                return;
            }
            this._goto(-100);
        });

        simpleEventBus.subscribe('keydown.arrowDown', () => {
            if (!this.flow && !this._canScroll()) {
                return;
            }
            this._goto(100);
        });
    }

    getCurrentSection() {
        return parseInt(-1 * this._getCurentScroll() / this._sectionHeight);
    }

    _setBusy(val) {
        this._isBusy = val;
    }

    _canScroll() {
        return !this._isBusy && !window.appState.videoIsPlaying;
    }

    _flow(change) {
        const lrScroll = clamp(this._getCurentScroll() - change, 0, -this._maxAbsScroll);
        this.$wrapper.attr('style', `margin-top:${lrScroll}px`);
    }

    _goto(change) {
        const currentSection = this.getCurrentSection();
        const goal = -clamp(this._sectionHeight * (currentSection + (change > 0 ? 1 : -1)), this._maxAbsScroll, 0);
        this._scrollToGoal(goal);
    }

    _getCurentScroll() {
        return parseFloat(this.$wrapper.css('marginTop'));
    }

    _magnet() {
        const currentSection = Math.round(Math.abs(this._getCurentScroll()) / this._sectionHeight);
        const goal = -currentSection * this._sectionHeight;
        this._scrollToGoal(goal);
    }

    _scrollToGoal(goal, after = noop) {
        if (!this._canScroll()) {
            return;
        }

        this._setBusy(true);

        simpleEventBus.emit('scroll.start', {sectionIndex: this.getCurrentSection()});

        this.$wrapper.attr('style', `margin-top:${goal}px`);

        const t = setTimeout(() => {
            this._setBusy(false);
            after();
            const sectionIndex = this.getCurrentSection();
            simpleEventBus.emit('scroll.end', {sectionIndex});
            this.$wrapper.focus();

            clearTimeout(t);
        }, 1050)
    }
}
