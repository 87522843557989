import Swiper from "swiper";

export function gallery() {
    new Swiper('.swiper-container', {
        direction: 'horizontal',
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        simulateTouch: false,
    })
}
