import $ from 'jQuery';
import { throttle } from './throttle';

export default function moving({ el, inverse, minWidth, rotate = 0}) {
    let movementStrength = 20,
        winHeight,
        winWidth,
        height,
        width,
        pageX,
        pageY,
        newvalueX = 0,
        newvalueY = 0,
        currentX = 0,
        currentY = 0,
        timerId,
        $el = $(el),
        $window = $(window),
        checkSizeWin = throttle(checkSizeWindow, 2000);

    minWidth = minWidth || 1280;

    $(document).ready(function () {
        checkSizeWindow();
        moveScene();
        $(window).mousemove(mousemove);
    });

    $window.on('resize', throttle(checkSizeWindow, 500));

    function mousemove(e) {
        if (winWidth >= minWidth) {
            changePosition(e);
        }
    }

    function changePosition(e) {
        pageX = e.pageX - (winWidth / 2);
        pageY = e.pageY - (winHeight / 2);
        newvalueX = width * pageX * -1 - 6;
        newvalueY = height * pageY * -1 - 6;
        checkSizeWin();
        clearTimeout(timerId);
        if (inverse) {
            moveSceneInverse();
        } else {
            moveScene();
        }
    }

    function moveSceneInverse() {
        let deltaX = (newvalueX - currentX) / 10;
        let deltaY = (newvalueY - currentY) / 10;
        if (Math.abs(deltaX) >= 0.1 || Math.abs(deltaY) >= 0.1) {
            currentX += deltaX;
            currentY += deltaY;

            if (rotate > 0) {
                $(el).css({ 'transform': `translate(calc(-50% + ${currentX}px), calc(-50% + ${currentY}px + 5.625rem )) rotate(${rotate}deg)` });
            } else {
                $(el).css({ 'transform': `translate(${currentX}px, ${currentY}px) rotate(${rotate}deg)` });
            }
            timerId = setTimeout(moveSceneInverse, 40);
        }
    }

    function moveScene() {
        let deltaX = (newvalueX + currentX) / 10;
        let deltaY = (newvalueY - currentY) / 10;
        if (Math.abs(deltaX) >= 0.1 || Math.abs(deltaY) >= 0.1) {
            currentX -= deltaX;
            currentY += deltaY;

            if (rotate > 0) {
                $(el).css({ 'transform': `translate(calc(-50% + 5.625rem + ${currentX}px), ${currentY}px) rotate(${rotate}deg)` });
            } else {
                $(el).css({ 'transform': `translate(${currentX}px, ${currentY}px) rotate(${rotate}deg)` });
            }
            timerId = setTimeout(moveScene, 40);
        }
    }

    function checkSizeWindow() {
        winHeight = $window.height();
        winWidth = $window.width();
        height = movementStrength / winHeight;
        width = movementStrength / winWidth;
    }
}
