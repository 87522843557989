import $ from 'jquery';
import { simpleEventBus } from "./simpleEventBus";

export function video() {
    simpleEventBus.subscribe('scroll.end', ({sectionIndex}) => {
        const $videos = $('video[data-section]');
        $videos.each((index, el) => {
            const sections = el.getAttribute('data-section').split(',');
            if (sections.filter(x => x === `${sectionIndex}`).length > 0) {
                el.play && el.play();
            } else {
                el.pause && el.pause();
            }
        });
    });

    simpleEventBus.subscribe('scroll.start', ({sectionIndex}) => {
        const $videos = $('video[data-section]');
        $videos.each((index, el) => {
            const sections = el.getAttribute('data-section').split(',');
            if (sections.filter(x => x === `${sectionIndex}`).length === 0) {
                el.pause && el.pause();
            }
        });
    });
}
