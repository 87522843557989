import $ from 'jquery';
import { simpleEventBus } from "./simpleEventBus";

export const menu = () => {
    $('.menu__main')
        .hover(() => {
            $('.menu__full')
                .addClass('menu__full-active');
            $('.menu__main').addClass('menu__full-active');
        });
    $('.menu__full')
        .mouseleave(() => {
            $('.menu__full')
                .removeClass('menu__full-active');
            $('.menu__main').removeClass('menu__full-active');
        });

    $('.menu__icon[data-section-number], .menu__list__item').click((e) => {
        const {currentTarget} = e;
        simpleEventBus.emit('menu.item.click');
        window.gotosection(currentTarget.getAttribute('data-section-number'));
    });

    simpleEventBus.subscribe('scroll.end', ({sectionIndex}) => {
        const section = sectionIndex + 1;
        $('.menu__icon').removeClass('menu__icon-active');
        $(`.menu__icon[data-section-number="${section}"]`).addClass('menu__icon-active');

        $('.menu__list__item').removeClass('menu__list__item-active');
        $(`.menu__list__item[data-section-number="${section}"]`).addClass('menu__list__item-active');

        $('.menu').attr('data-page', section);
    });

    window.hideMenu = () => {
        $('.menu').addClass('menu-hide');
    };

    window.showMenu = () => {
        $('.menu').removeClass('menu-hide');
    };
};

export default menu;
