import $ from 'jquery';

$('.contacts').click(() => {
    $('.contacts__content').addClass('contacts__content-active');
    $('.contacts').addClass('contacts-active');
});

$('.contacts__content__close').click((e) => {
    e.stopPropagation();
    $('.contacts__content').removeClass('contacts__content-active');
    $('.contacts').removeClass('contacts-active');
});

$(document).on('click', (e) => {
    const el = '.contacts';
    if ($(e.target).closest(el).length) return;
    $('.contacts__content').removeClass('contacts__content-active');
});

window.showContacts = () => {
    $('.contacts').addClass('contacts--opened');
};

window.hideContacts = () => {
    $('.contacts:not(.contacts-active)').removeClass('contacts--opened');
};
