import $ from 'jquery';

export function language() {
    if (navigator.language.toLowerCase().indexOf('ru') !== -1) {
        setLanguage('ru');
    } else {
        setLanguage('en');
    }

    $('.language-btn').click(e => {
        const lang = e.currentTarget.getAttribute('data-language');
        setLanguage(lang);
    });
}

function setLanguage(lang) {
    $('body').removeClass('lang-en lang-ru');
    $('body').addClass(`lang-${lang}`);

    $('.language-btn').removeClass('active');
    $(`.language-btn[data-language="${lang}"]`).addClass('active');
}
