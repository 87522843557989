export function clamp(x, max, min) {
    if (x > max) {
        return max;
    } else if (x < min) {
        return min;
    } else {
        return x;
    }
}

export function noop() {

}

export function coroutine(callback, count, period, after = noop) {
    let counter = 0;

    const interval = setInterval(() => {
        callback();
        counter++;
        if (counter === count) {
            clearInterval(interval);
            after();
        }
    }, period / count);
}
