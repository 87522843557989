import $ from 'jquery';
import { Longread } from './longread';
import home from './home';
import { connection } from './connection';
import { Loader } from './loader';
import { menu } from './menu';
import { application } from './application';
import { exploitation } from "./exploitation";
import { devtest } from "./devtest";
import { video } from "./video";
import { simpleEventBus } from "./simpleEventBus";
import { language } from "./language";
import { gallery } from "./gallery";
import bgMoving from './backgoundMove';
import './contacts';

window.appState = {
    videoIsPlaying: false,
};

$(document)
    .ready(() => {
        const loader = new Loader();
        const longread = new Longread({flow: false, noDrag: true});

        simpleEventBus.subscribe('video.start', () => {
            window.appState.videoIsPlaying = true;
        });

        simpleEventBus.subscribe('video.end', () => {
            window.appState.videoIsPlaying = false;
        });

        document.addEventListener('keydown', (e) => {
            const {key} = e;
            if (key === "ArrowUp" || key === "PageUp" || key === "Home") {
                simpleEventBus.emit('keydown.arrowUp');
            }
            if (key === "ArrowDown" || key === "PageDown" || key === "End") {
                simpleEventBus.emit('keydown.arrowDown');
            }
            if (key === 'Escape') {
                simpleEventBus.emit('keydown.escape');
            }
        });

        $('.afterLoader').hide();

        loader.onFinish(() => {
            $('.afterLoader').show();

            const ua = window.navigator.userAgent.toLowerCase();
            if (ua.indexOf('msie') !== -1 || ua.indexOf('trident') !== -1 || ua.indexOf('edge') !== -1) {
                $('body').addClass('ie');
            }

            longread.go();
            menu();
            home();
            connection();
            exploitation();
            application();
            devtest();
            video();
            language();
            gallery();
            bgMoving({
                el: '.home__truba',
                inverse: true,
            });
            bgMoving({
                el: '.exploitation__pipe-img',
                inverse: true,
                rotate: 90,
            });
        });

        loader.go();
    });
