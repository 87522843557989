import $ from 'jquery';

const ANIMATION_FILL_TIMEOUT = 3500;
const ANIMATION_LINE_TIMEOUT = 1000;

let timeouts = [];

export function devtest() {
    setActive('1');

    $('.devtest__title').click((e) => {
        const {currentTarget} = e;
        const activeSection = currentTarget.getAttribute('data-section');

        setActive(activeSection);
    });
}

function addTitlesClicks() {
    $('.devtest__graph-title-1').click(handleTitle1Click);
    $('.devtest__graph-title-2').click(handleTitle2Click);
    $('.devtest__graph-title-3').click(handleTitle3Click);
}

function removeTitlesClicks() {
    $('.devtest__graph-title-1').off();
    $('.devtest__graph-title-2').off();
    $('.devtest__graph-title-3').off();
}

function handleTitle1Click() {
    $('.devtest__graph__filled').addClass('title1-clicked');
    $('.lines-filled').addClass('title1-clicked');
    $('.devtest__graph__filled').removeClass('title2-clicked');
    $('.lines-filled').removeClass('title2-clicked');
}

function handleTitle2Click() {
    $('.devtest__graph__filled').addClass('title2-clicked');
    $('.lines-filled').addClass('title2-clicked');
    $('.devtest__graph__filled').removeClass('title1-clicked');
    $('.lines-filled').removeClass('title1-clicked');
}

function handleTitle3Click() {
    $('.devtest__graph__filled').removeClass('title2-clicked');
    $('.lines-filled').removeClass('title2-clicked');
    $('.devtest__graph__filled').removeClass('title1-clicked');
    $('.lines-filled').removeClass('title1-clicked');
}

function setActive(section) {
    $('.devtest__text').removeClass('active');
    $('.devtest__right').removeClass('active--1');
    $('.devtest__right').removeClass('active--2');

    $(`.devtest__text--${section}`).addClass('active');
    $('.devtest__right').addClass(`active--${section}`);

    if (section === '2') {
        startAnimation();
    } else {
        handleTitle3Click();
        removeTitlesClicks();
        resetAnimation();
    }
}

function startAnimation() {
    if ($('body').hasClass('ie')) {
        $('.lines-unfilled .line-3').addClass('line-3--active');

        $('.lines-unfilled .line-3').addClass('line-3--active');
        $('.lines-filled .line-3').addClass('line-3--active');
        $('.oval-filled').addClass('oval-filled--3');
        $('.oval4-filled').addClass('oval4-filled--active');
        return;
    }

    const timeout = setTimeout(() => {
        animateFirstStage();
    }, 100);
    timeouts.push(timeout);
}

function animateFirstStage() {
    $('.oval-filled').addClass('oval-filled--1');
    $('.devtest__graph__filled').addClass('devtest__graph__filled--1');
    $('.devtest__graph-title-1').addClass('devtest__graph-title--active');

    const timeout = setTimeout(() => {
        $('.lines-unfilled .line-1').addClass('line-1--active');
        $('.lines-filled .line-1').addClass('line-1--active');
        const lineTimeout = setTimeout(() => {
            animateSecondStage();
        }, ANIMATION_LINE_TIMEOUT);

        timeouts.push(lineTimeout);
    }, ANIMATION_FILL_TIMEOUT);

    timeouts.push(timeout);
}

function animateSecondStage() {
    const timeout = setTimeout(() => {
        $('.devtest__graph__filled').removeClass('devtest__graph__filled--1');

        $('.oval-filled').removeClass('oval-filled--1');
        $('.oval-filled').addClass('oval-filled--2');

        const secondStageTimout = setTimeout(() => {
            $('.devtest__graph__filled').addClass('devtest__graph__filled--2');
            animateThirdStage();
        }, 100);
        timeouts.push(secondStageTimout);
    }, 2000);

    timeouts.push(timeout);
}

function animateThirdStage() {
    $('.devtest__graph-title-1').removeClass('devtest__graph-title--active');
    $('.devtest__graph-title-1').addClass('devtest__graph-title--non-active');
    $('.devtest__graph-title-2').addClass('devtest__graph-title--active');

    const timeout = setTimeout(() => {
        $('.lines-unfilled .line-2').addClass('line-2--active');
        $('.lines-filled .line-2').addClass('line-2--active');

        const thirdStageTimeout = setTimeout(() => {

            $('.devtest__graph__filled').removeClass('devtest__graph__filled--2');

            $('.oval-filled').removeClass('oval-filled--2');
            $('.oval-filled').addClass('oval-filled--3');

            const lineTimeout = setTimeout(() => {
                $('.devtest__graph__filled').addClass('devtest__graph__filled--3');

                $('.oval4-filled').addClass('oval4-filled--active');
                animateFourthStage();
            }, ANIMATION_LINE_TIMEOUT);

            timeouts.push(lineTimeout);
        }, 2000);

        timeouts.push(thirdStageTimeout);
    }, ANIMATION_FILL_TIMEOUT);

    timeouts.push(timeout);
}

function animateFourthStage() {
    $('.devtest__graph-title-2').removeClass('devtest__graph-title--active');
    $('.devtest__graph-title-2').addClass('devtest__graph-title--non-active');
    $('.devtest__graph-title-1').addClass('devtest__graph-title--non-active-2');
    $('.devtest__graph-title-3').addClass('devtest__graph-title--active');
    const timeout = setTimeout(() => {

        $('.lines-unfilled .line-3').addClass('line-3--active');

        $('.lines-unfilled .line-3').addClass('line-3--active');
        $('.lines-filled .line-3').addClass('line-3--active');

        $('.devtest__graph-title').addClass('devtest__graph-title--interactive');

        addTitlesClicks();
    }, ANIMATION_FILL_TIMEOUT);

    timeouts.push(timeout);
}

function oval1Fill() {
    const timeout = setTimeout(() => {
        $('.devtest__graph__filled').addClass('devtest__graph__filled--2');
        oval2Fill();
    }, 5000);

    timeouts.push(timeout);
}

function oval2Fill() {
    $('.oval-filled').removeClass('oval-filled--1');
    $('.oval-filled').addClass('oval-filled--2');
    const timeout = setTimeout(() => {
    }, 5000);
    timeouts.push(timeout);
}

function resetAnimation() {
    if ($('body').hasClass('ie')) {
        return;
    }
    $('.devtest__graph__filled').removeClass('devtest__graph__filled--1');
    $('.devtest__graph__filled').removeClass('devtest__graph__filled--2');
    $('.devtest__graph__filled').removeClass('devtest__graph__filled--3');
    $('.oval-filled').removeClass('oval-filled--1');
    $('.oval-filled').removeClass('oval-filled--2');
    $('.oval-filled').removeClass('oval-filled--3');

    $('.devtest__graph-title').removeClass('devtest__graph-title--active');
    $('.devtest__graph-title').removeClass('devtest__graph-title--non-active');
    $('.devtest__graph-title').removeClass('devtest__graph-title--non-active-2');
    $('.devtest__graph-title').removeClass('devtest__graph-title--interactive');

    $('.oval4-filled').removeClass('oval4-filled--active');

    $('.lines-unfilled .line-1').removeClass('line-1--active');
    $('.lines-unfilled .line-2').removeClass('line-2--active');
    $('.lines-unfilled .line-3').removeClass('line-3--active');

    $('.lines-filled .line-1').removeClass('line-1--active');
    $('.lines-filled .line-2').removeClass('line-2--active');
    $('.lines-filled .line-3').removeClass('line-3--active');

    timeouts.forEach(t => clearTimeout(t));
    timeouts = [];
}

